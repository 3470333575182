body {
  margin: 0;
  font-family: "Pretendard", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
  background-color: #fff !important;
}

.desktop ::-webkit-scrollbar-track {
  background-clip: padding-box;
  border: solid transparent;
  border-width: 0 0 0 4px;
  display: none;
}
.desktop ::-webkit-scrollbar-thumb {
  min-height: 28px;
  padding: 100px 0 0;
  background-color: hsla(0,0%,100%,.22);
  background-clip: padding-box;
  border: 1px solid transparent;
  border-left-width: 6px;
  box-shadow: inset 1px 1px 0 rgba(0,0,0,.1), inset 0 -1px 0 rgba(0,0,0,.07);
  background: hsla(0,0%,100%,.22);
  background: hsla(220,9%,87%,.471);
  border-width: 0;
  border-radius: 25px;
}

.desktop ::-webkit-scrollbar-corner {
  background: transparent;
}

.desktop ::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}

.desktop ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  overflow: visible;
  box-sizing: border-box;
  width: 6px;
  background-color: transparent;
}

[data-simplebar] {
  width: 100%;
  height: 100%;
}

html.mobile {
  background-color: #1e1e1e;
}